var render = function () {
  var _vm$validation;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-tax-number', (_vm$validation = _vm.validation) !== null && _vm$validation !== void 0 && _vm$validation.$error ? 'error' : '']
  }, [_c('p', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "sygni-tax-number__container"
  }, [_c('div', {
    staticClass: "sygni-tax-number__countries"
  }, [_c('sygni-select', {
    attrs: {
      "label": "",
      "searchable": true,
      "placeholder": "Select",
      "options": _vm.countryOptions
    },
    model: {
      value: _vm.field.country,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "country", $$v);
      },
      expression: "field.country"
    }
  })], 1), _c('div', {
    staticClass: "sygni-tax-number__input"
  }, [_c('sygni-input', {
    attrs: {
      "placeholder": "Enter tax number"
    },
    model: {
      value: _vm.field.value,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "value", $$v);
      },
      expression: "field.value"
    }
  })], 1)]), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }