















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from "vue-property-decorator";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import SygniSelect from './SygniSelect.vue';
import SygniInput from './SygniInput.vue';
import { mapGetters } from 'vuex';
import { GlobalSelects } from '@/modules/shared/dictionary-store/types';
import { SelectOption } from '@/store/types';

// TODO: Make some description about how does it works.
// Currency format cant be used with number input
// Refactor this someday please
@Component({
  components: { SygniSelect, SygniInput, SygniInputError },
  computed: {
    ...mapGetters('dictionary', {
      globalSelects: 'globalSelects'
    }),
  }
})
export default class SygniTaxNumberInput extends Vue {
  @Prop({ default: 'TAX NUMBER' }) label: string;
  @Prop({ default: 'PL' }) defaultCountry: string;
  @Prop({ default: null }) validation: any;

  globalSelects!: GlobalSelects;

  field: { country: string, value: string } = {
    country: 'PL',
    value: null
  }

  value: string = null

  get countryOptions() {
    const supportedCountryCodes = ['PL', 'AT', 'BE', 'BG', 'CHE', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'GB', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'NO', 'NZ', 'PT', 'RO', 'RS', 'RU', 'SE', 'SI', 'SK']
    let options = this.globalSelects?.countries?.map(((el: SelectOption<string>) => {
      const countryCode = this.getCountryCode(el.value)
      return { label: countryCode, value: countryCode, country: el.label }
    })) || []

    options = [
      ...options?.filter((el: SelectOption<string>) => supportedCountryCodes?.includes(el.value))?.sort((a: any, b: any) => {
        const labelA = a?.label?.toUpperCase();
        const labelB = b?.label?.toUpperCase();
        return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
      }),
      ...options?.filter((el: SelectOption<string>) => !supportedCountryCodes?.includes(el.value))?.sort((a: any, b: any) => {
        const labelA = a?.label?.toUpperCase();
        const labelB = b?.label?.toUpperCase();
        return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
      })
    ]

    // pl - poland [v] 
    // at - austria [v]
    // be - belgium []
    // bg - bulgaria []
    // ch (che) - switzerland []
    // cy - cyprus []
    // cz - czech republic []
    // de - germany []
    // dk - denmark []
    // ee - estonia []
    // gr (el) - greece []
    // es - spain []
    // fi - finland []
    // fr - france []
    // gb - united kingdom []
    // hr - croatia []
    // hu - hungary []
    // ie - ireland []
    // it - italy []
    // lt - lithuania []
    // lu - luxemburg []
    // lv - latvia []
    // mt - malta []
    // nl - netherland []
    // no - norway []
    // nz - new zealand []
    // pt - portugal []
    // ro - romania []
    // rs - serbia []
    // ru - russia []
    // se - sweden []
    // si - slovenia []
    // sk - slovakia []



    return options
  }

  getCountryCode(countryCode: string) {
    switch (countryCode?.toUpperCase()) {
      case 'CH':
        return 'CHE'
      case 'GR':
        return 'EL'
      default:
        return countryCode?.toUpperCase()
    }
  }

  mounted() {
    if (this.defaultCountry) {
      this.field.country = this.defaultCountry
    }

    if (this.$attrs?.value) {
      const containsCode = (/^[A-Za-z]+$/).test(this.$attrs.value?.substring(0, 1)) || (/^[A-Za-z]+$/).test(this.$attrs.value?.substring(1, 2));

      if (containsCode) {
        const code = this.$attrs.value?.substring(0, 2)

        this.field.country = code
        this.field.value = this.$attrs.value?.substring(2)
      } else {
        this.field.country = 'PL'
        this.field.value = this.$attrs.value
      }
    }
  }

  @Watch('field', { deep: true }) onFieldChange() {
    if (this.field?.value) {
      this.value = `${this.field?.country || ''}${this.field?.value || ''}`
    } else {
      this.value = ''
    }
    this.$emit('blur')
  }

  @Watch('value') onValueChange(newValue: string) {
    this.$emit('input', newValue)
  }
}
